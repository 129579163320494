import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#232536]">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-white sm:text-center">
            © 2024{" "}
            <a href="https://greenmantis.com.np/" className="hover:underline text-yellow-500">
              GreenMantis
            </a>
            . All Rights Reserved.
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
            <a href="https://www.facebook.com/YatuInternational" className="text-white hover:text-gray-900">
              <i class="fa fa-facebook"></i>

              <span className="sr-only" >Facebook page</span>
            </a>

            <a href="#" className="text-white hover:text-gray-900 ms-5">
              <i class="fa fa-twitter"></i>

              <span className="sr-only">Twitter page</span>
            </a>
            <a href="#" className="text-white hover:text-gray-900 ms-5">
              <i class="fa fa-instagram"></i>
              <span className="sr-only">Instagram account</span>
            </a>
            <a href="#" className="text-white hover:text-gray-900 ms-5">
              <i class="fa fa-linkedin"></i>
              <span className="sr-only">LinkedIn account</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
