import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from "../assets/logos/newlogo.png";
import { Link, useLocation } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
  // Get the current location using useLocation hook from react-router-dom
  const location = useLocation();

  // Function to handle smooth scrolling
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Function to handle clicking on navbar links
  const handleNavbarLinkClick = (id) => {
    // Scroll to section only if not on the home page
    if (location.pathname !== '/') {
      // Navigate to home page first
      window.location.href = '/';
    }
    // Scroll to section
    scrollToSection(id);
  };

  return (
    <Disclosure as="nav" className="bg-[#232536] sticky top-0 z-50">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-between sm:items-stretch sm:justify-between">
                <div className="flex flex-shrink-0 items-center h-[50px]">
                  <Link to="/">
                    <img
                      className="h-[150px] lg:h-[250px]"
                      src={Logo}
                      alt="yatu Company"
                    />
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    <a
                      href="#home"
                      onClick={() => handleNavbarLinkClick('home')}
                      className={classNames(
                        'text-white',
                        'rounded-md px-3 py-2 text-sm font-medium'
                      )}
                    >
                      Home
                    </a>
                    <a
                      href="#about"
                      onClick={() => handleNavbarLinkClick('about')}
                      className={classNames(
                        'text-gray-300 hover:text-white',
                        'rounded-md px-3 py-2 text-sm font-medium'
                      )}
                    >
                      About Us
                    </a>
                    <a
                      href="#study-abroad"
                      onClick={() => handleNavbarLinkClick('study-abroad')}
                      className={classNames(
                        'text-gray-300 hover:text-white',
                        'rounded-md px-3 py-2 text-sm font-medium'
                      )}
                    >
                      Study Abroad
                    </a>
                    <a
                      href="#services"
                      onClick={() => handleNavbarLinkClick('services')}
                      className={classNames(
                        'text-gray-300 hover:text-white',
                        'rounded-md px-3 py-2 text-sm font-medium'
                      )}
                    >
                      Services
                    </a>
                    <a
                      href="#contact"
                      onClick={() => handleNavbarLinkClick('contact')}
                      className={classNames(
                        'text-gray-300 hover:text-white',
                        'rounded-md px-3 py-2 text-sm font-medium'
                      )}
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              <a
                href="#home"
                onClick={() => handleNavbarLinkClick('home')}
                className={classNames(
                  'bg-gray-900 text-white',
                  'block rounded-md px-3 py-2 text-base font-medium'
                )}
              >
                Home
              </a>
              <a
                href="#about"
                onClick={() => handleNavbarLinkClick('about')}
                className={classNames(
                  'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'block rounded-md px-3 py-2 text-base font-medium'
                )}
              >
                About Us
              </a>
              <a
                href="#study-abroad"
                onClick={() => handleNavbarLinkClick('study-abroad')}
                className={classNames(
                  'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'block rounded-md px-3 py-2 text-base font-medium'
                )}
              >
                Study Abroad
              </a>
              <a
                href="#services"
                onClick={() => handleNavbarLinkClick('services')}
                className={classNames(
                  'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'block rounded-md px-3 py-2 text-base font-medium'
                )}
              >
                Services
              </a>
              <a
                href="#contact"
                onClick={() => handleNavbarLinkClick('contact')}
                className={classNames(
                  'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'block rounded-md px-3 py-2 text-base font-medium'
                )}
              >
                Contact Us
              </a>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
