import React from 'react'
import img2 from "../assets/images/2.png"
import { Link } from 'react-router-dom'
import canada from "../assets/images/canada.png"


const StudyCanada = () => {
    return (
        <>
            <img src={img2} alt="img1" className="lg:w-full h-[200px] md:h-fit lg:h-fit  absolute filter grayscale" />
        
            <Link to="/">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className='lg:w-10 lg:h-10 h-7 w-7 absolute left-0 rounded-full bg-slate-600 lg:p-2 p-1 hover:bg-gray-800 m-2'>
                    <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                    <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                </svg>
            </Link>
      
            <div className='lg:pt-[400px] md:pt-[200px] pt-[200px]'>

            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2'>
                <div>
                <div className='text-gray-600 lg:text-[38px] text-[24px] font-bold lg:px-4 px-2' data-aos="fade-right"> Study in Canada</div>
                    <p className='lg:p-5 p-3 text-justify lg:text-[16px] text-[12px]' data-aos="fade-right">


                        Studying in Canada offers a world-class education in a welcoming and diverse environment. Renowned for its high-quality academic programs and top-ranking universities, Canada provides international students with excellent opportunities for personal and professional growth. With its multicultural society and safe cities, Canada offers a rich cultural experience that fosters global perspectives and lifelong friendships. Additionally, favorable post-graduation work permit options allow students to gain valuable work experience while exploring the country's stunning natural landscapes and vibrant cities. A degree from a Canadian institution is highly respected worldwide, providing students with a solid foundation for future success in their careers and beyond.    </p>
                        <Link to="/" href="#contact">


<button className='bg-rose-600 text-white p-3 mx-5 hover:bg-rose-800' data-aos="fade-right"> Contact Us for More detail</button>
</Link>                </div>
                <div>
                    <img src={canada} alt="img1" className="lg:w-full h-[300px] md:h-fit lg:h-fit floating lg:pt-0 pt-3" />
                </div>



            </div>
            <div className='text-[24px] font-bold text-gray-600 text-center pt-3 pb-3' data-aos="fade-up">
                Why Study in the Canada?
            </div>
            <div className='lg:p-5 p-3 text-justify lg:text-[16px] text-[12px]'>
                High-Quality Education: Canada is renowned for its high-quality education system, with top-ranking universities and colleges offering excellent academic programs across various fields.

                Cultural Diversity: Canada is known for its multicultural society, providing students with the opportunity to experience diverse cultures and perspectives, enriching their educational experience.

                Safe and Welcoming Environment: Canada is consistently ranked as one of the safest countries in the world, offering a welcoming and inclusive environment for international students to live and study.

                Post-Study Work Opportunities: Canada offers favorable post-graduation work permit options, allowing international students to gain valuable work experience after completing their studies.

                Research Opportunities: Canada is a leader in research and innovation, with opportunities for students to engage in cutting-edge research projects and collaborate with leading scholars in their field.

            </div>



        </>
    )
}


export default StudyCanada