import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./dashboard/HomePage";
import Navbar from "./Component/Navbar";
import Footer from "./Component/Footer";
import StudyUSA from "./study/StudyUSA";
import StudyCanada from "./study/StudyCanada";
import StudyAustralia from "./study/StudyAustralia";
import StudyUk from "./study/StudyUk";
import StudyOther from "./study/StudyOther";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <Router>
      <Navbar/>
     <ScrollToTop/>
      <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/usa" element={<StudyUSA/>}/>
      <Route path="/canada" element={<StudyCanada/>}/>
      <Route path="/australia" element={<StudyAustralia/>}/>
      <Route path="/uk" element={<StudyUk/>}/>
      <Route path="/other" element={<StudyOther/>}/>


      </Routes>
      <Footer/>
    
    </Router>
  );
}

export default App;
