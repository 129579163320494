import React, { useEffect } from "react";
import KeenSlider from 'https://cdn.jsdelivr.net/npm/keen-slider@6.8.6/+esm';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import gmat from "../assets/gmat.jpg";
import gre from "../assets/gre.jpg";
import sat from "../assets/sat.jpg";
import ielts from "../assets/ielts.jpg";
import toefl from "../assets/toefl.jpg";
import usa from "../assets/images/nik-shuliahin-L4JWn8HHJ30-unsplash.jpg"
import canada from "../assets/images/jason-hafso-C2keINMOhIE-unsplash.jpg"
import uk from "../assets/images/chris-lawton-QPOaQ2Kp80c-unsplash.jpg"
import aus from "../assets/images/caleb-JmuyB_LibRo-unsplash.jpg"
import other from "../assets/images/ansgar-scheffold-mtfTz0FnwBw-unsplash.jpg"
import img2 from "../assets/images/bgg.png"
import team1 from "../assets/images/sujit.png"
import img1 from "../assets/images/newbg.jpg";
import img3 from "../assets/images/bg.png";
import con from "../assets/logos/operator.png";
import doc from "../assets/logos/documents.png";
import clas from "../assets/logos/blackboard.png";
import visa from "../assets/logos/visa.png"
import facebook from "../assets/logos/Facebook.png"
import insta from "../assets/logos/Instagram.png"
import links from "../assets/LinkedIn.png"
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

// ..
AOS.init();

const HomePage = () => {
    const options = {
        loop: true,
        margin: 20,
        responsiveClass: true,
        nav: true,
        autoplay: true, // Enable autoplay
        autoplayTimeout: 2000,
        autoplaySpeed: 900,// Set autoplay timeout to 2 seconds
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            600: {
                items: 3,
                nav: false
            },
            1000: {
                items: 4,
                nav: true,
                loop: true
            }
        }
    };
    const carouselOptions = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplaySpeed: 800,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            600: {
                items: 2,
                nav: false
            },
            1000: {
                items: 3,
                nav: true,
                loop: true // Set loop to true for screens wider than 1000px
            }
        }
    };

    useEffect(() => {
        const keenSlider = new KeenSlider(
            '#keen-slider',
            {
                loop: true,
                slides: {
                    origin: 'center',
                    perView: 1.25,
                    spacing: 16,
                },
                breakpoints: {
                    '(min-width: 1024px)': {
                        slides: {
                            origin: 'auto',
                            perView: 1.5,
                            spacing: 32,
                        },
                    },
                },
            },
            []
        );

        const keenSliderPrevious = document.getElementById('keen-slider-previous');
        const keenSliderNext = document.getElementById('keen-slider-next');

        const keenSliderPreviousDesktop = document.getElementById('keen-slider-previous-desktop');
        const keenSliderNextDesktop = document.getElementById('keen-slider-next-desktop');

        keenSliderPrevious.addEventListener('click', () => keenSlider.prev());
        keenSliderNext.addEventListener('click', () => keenSlider.next());

        keenSliderPreviousDesktop.addEventListener('click', () => keenSlider.prev());
        keenSliderNextDesktop.addEventListener('click', () => keenSlider.next());

        return () => {
            // Cleanup code if needed
            keenSlider.destroy();
        };
    }, []);

    return (
        <>
       
            <section className="" id="home">

                <img src={img1} alt="image1" className='h-[60vh] lg:h-[600px] w-full absolute  ' style={{backgroundRepeat:"no-repeat",backgroundSize:"cover"}}  id="coloredImage" 
  loading="lazy"  />

                <div className=' container relative  px-2'>
                    <div className='lg:pt-[100px] md:pt-[50px] pt-[80px] font-bold'data-aos="fade-right">Study Abroad</div>
                    <div className='text-gray-800 text-[24px] lg:text-[40px] font-extrabold drop-shadow-md' data-aos="fade-right"> YATU INTERNATIONAL<br />EDUCATION CONSULTANCY</div>
                    <div className="hidden lg:block" data-aos="fade-right">Welcome to YATU, your trusted partner in navigating the <br /> pathways to academic success abroad.</div>
                    
                    <button className='text-white lg:p-3 p-2  lg:px-5 px-2  bg-[#232536]  hover:bg-yellow-500 font-bold mt-3 shadow-xl text-[12px]' data-aos="fade-left" href="#about" id="about"> <a href="#about" id="about">Read More</a></button>
                </div>

            </section>
            {/* <div className='about-us container mt-[100px]  lg:mt-[280px]'>
                <div className="grid grid-cols-1 gap-2  md:grid-cols-3 lg:grid-cols-7">
                    <div class="col-span-4">
                        <div className="text-[24px] font-bold text-gray-600 ">
                            About Us
                        </div>
                        <div className="w-100 p-5 border border-gray-200 text-gray-700 ">
                            <img src={img1} className="w-fit-content" />
                            <div className="pt-2 text-[28px] font-extrabold ">
                                YATU Educational Consultancy Pvt Ltd
                            </div>
                            <div className="pt-1 text-justify">Welcome to YATU Educational Consultancy Pvt Ltd, your dedicated ally in guiding you through the intricate pathways to academic triumph. Nestled in the vibrant heart of Bhaktapur, Nepal, our consultancy is committed to empowering both students and educators with bespoke educational solutions and personalized guidance. With a firm belief in the transformative power of education, we strive to foster a supportive environment where individuals can realize their full potential and achieve their academic goals with confidence.
                                <br />

                                At YATU, we understand that each educational journey is unique, which is why we offer tailored solutions designed to address the specific needs and aspirations of every client. Whether you're a student navigating the complexities of university admissions or an educator seeking professional development opportunities, our experienced team is here to provide expert guidance and unwavering support every step of the way. Let us be your trusted partner on your quest for academic excellence and personal growth.</div>


                        </div>
                    </div>
                    <div class=" col-span-3">
                        <div className="text-[24px] font-bold text-gray-600 px-3">
                            YATU Educational Consultancy Pvt Ltd provides a range of tailored services</div>
                        <div className="p-3">
                            <div className="text-[14px] text-[#592EA9]">Counseling</div>
                            <div className="text-[16px] font-medium">
                                Our experienced counselors offer personalized support at every stage of your educational journey, from program selection to career planning.</div>

                        </div>
                        <div className="p-3">
                            <div className="text-[14px] text-[#592EA9]">Documentation</div>
                            <div className="text-[16px] font-medium">We assist students with documentation, ensuring accuracy and timely submission of all required paperwork, including transcripts, recommendation letters, and visa documents.</div>

                        </div>
                        <div className="p-3 bg-[#FBF6EA]">
                            <div className="text-[14px] text-[#592EA9]">Pre and Post Visa Services</div>
                            <div className="text-[16px] font-medium">
                                We offer expert visa assistance, easing the process for students, and provide post-visa support for a smooth transition, including accommodation and cultural adaptation help.</div>

                        </div>
                        <div className="p-3">
                            <div className="text-[14px] text-[#592EA9]">Classes</div>
                            <div className="text-[16px] font-medium">We offer comprehensive preparation classes for standardized tests such as IELTS, PTE, TOEFL, SAT, DuoLingo, GRE, and GMAT. Our experienced instructors use proven strategies and resources to help students achieve their desired scores and excel in their academic pursuits.</div>

                        </div>
                    </div>

                </div>


            </div> */}
            <section className="aboutUs container none " id="about">
                <img src={img2} alt="image1" className="absolute w-[50%] mt-[100px] lg:pl-20 h-[400px] md:mt-[350px] lg:mt-[280px] hidden lg:block" />
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className="lg:pl-[80%] lg:pt-0 pt-[20vh]">

                        <div className="bg-white lg:mt-[350px] lg:w-[50%] md:mt-[200px] absolute lg:p-5 pb-2 px-2 lg:pb-10 " data-aos="fade-left"  >
                            <div className="lg:text-[24px] text-[18px] font-bold text-gray-600 lg:pt-0 pt-5">
                                About Us
                            </div>
                            <div className="pt-1 text-justify text-[12px] lg:text-[16px]">Welcome to YATU Educational Consultancy Pvt Ltd, your dedicated ally in guiding you through the intricate pathways to academic triumph. Nestled in the vibrant heart of Bhaktapur, Nepal, our consultancy is committed to empowering both students and educators with bespoke educational solutions and personalized guidance. With a firm belief in the transformative power of education, we strive to foster a supportive environment where individuals can realize their full potential and achieve their academic goals with confidence.
                                <br />

                                {/* <span className="hidden lg:block"> At YATU, we understand that each educational journey is unique, which is why we offer tailored solutions designed to address the specific needs and aspirations of every client. Whether you're a student navigating the complexities of university admissions or an educator seeking professional development opportunities, our experienced team is here to provide expert guidance and unwavering support every step of the way. Let us be your trusted partner on your quest for academic excellence and personal growth.</span></div> */}
                            </div>



                        </div>
                    </div>

                </div>


            </section>
            <section id="study-abroad" className="country bg-gray-100 mt-[300px]  lg:mt-[720px] md:mt-[600px] " style={{ backgroundImage: `url(${img3})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                <div className="lg:text-[24px] text-[18px] font-bold text-gray-600 text-center pt-3" data-aos="fade-up">
                    Study Abroad
                </div>
                <div className="text-center lg:text-[28px] text-[20px] text-gray-700 " data-aos="fade-up">Your scholastic objectives are ready to be fulfilled.</div>

                <div className="mx-2" data-aos="zoom-in">
                    <OwlCarousel className="grid grid-cols-2 lg:grid-cols-5 container mt-3 pt-3 gap-3 lg:px-[100px] " {...carouselOptions}>


                        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow   overflow-hidden">
                            <Link to="/usa">
                                <img class="rounded-t-lg h-[150px] lg:h-[180px] object-cover w-full  hover:scale-110 ease-in duration-300" src={usa} alt="" />
                            </Link>
                            <div class="p-5">
                             
                                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">Study in USA</h5>
                               
                                <p class="mb-3 font-normal text-gray-700  ">Premier education system, diverse programs, vibrant culture.</p>

                            </div>
                        </div>


                        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow   overflow-hidden">
                            <Link to="/canada">
                                <img class="rounded-t-lg h-[150px] lg:h-[180px] object-cover w-full  hover:scale-110 ease-in duration-300" src={canada} alt="canada" />
                            </Link>
                            <div class="p-5">
                              
                                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">Study in Canada</h5>
                              
                                <p class="mb-3 font-normal text-gray-700  ">Inclusive society, top-notch education, stunning landscapes.</p>

                            </div>
                        </div>
                        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow   overflow-hidden">
                            <Link to="/uk">
                                <img class="rounded-t-lg h-[150px] lg:h-[180px] object-cover w-full  hover:scale-110 ease-in duration-300" src={uk} alt="canada" />
                            </Link>
                            <div class="p-5">
                               
                                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">Study in UK</h5>
                             
                                <p class="mb-3 font-normal text-gray-700  "> Rich academic heritage, prestigious universities, cultural experiences.</p>

                            </div>
                        </div>

                        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow   overflow-hidden">
                            <Link to="/australia" herf="#australia">
                                <img class="rounded-t-lg w-full  h-[150px] lg:h-[180px]  object-cover hover:scale-110 ease-in duration-300  " src={aus} alt="canada" />
                            </Link>
                            <div class="p-5">
                                
                                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">Study in Australia</h5>
                               
                                <p class="mb-3 font-normal text-gray-700  "> High-quality education, multicultural environment, outdoor adventures.</p>

                            </div>
                        </div>

                        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow   overflow-hidden">
                            <Link to="/other">
                                <img class="rounded-t-lg h-[150px] lg:h-[180px] object-cover w-full  hover:scale-110 ease-in duration-300" src={other} alt="other" />
                            </Link>
                            <div class="p-5">
                             
                                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">Study in other</h5>
                               
                                <p class="mb-3 font-normal text-gray-700  ">Varied opportunities worldwide for academic and cultural exploration.</p>

                            </div>
                        </div>

                    </OwlCarousel>

                </div>

            </section>
            <section id="services" className="servies container  px-2 mt-5 pb-5" >
                <div className="lg:text-[24px] text-[18px] font-bold text-gray-600 text-center " data-aos="fade-up">
                    Our Services
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-5 container pt-3 pb-5">


                    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow " data-aos="zoom-in">
                        <div className="p-2 bg-[#FBF6E4] w-fit rounded-lg">
                            <img src={con} className="h-7" alt="con"/>
                        </div>

                      
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">Counseling</h5>
                       
                        <p class="mb-3 font-normal text-gray-500 ">Personalized support from program selection to career planning</p>

                    </div>
                    <div class="max-w-sm p-6 bg-[#FFD050] border border-gray-200 rounded-lg shadow " data-aos="zoom-in">
                        <div className="p-2 bg-[#FBF6E4] w-fit rounded-lg">
                            <img src={doc} className="h-7" alt="doc" />
                        </div>

                     
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">Documentation</h5>
                     
                        <p class="mb-3 font-normal text-gray-500 ">Accurate, timely handling of academic and visa paperwork.</p>

                    </div>
                    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow " data-aos="zoom-in">
                        <div className="p-2 bg-[#FBF6E4] w-fit rounded-lg">
                            <img src={visa} className="h-7" alt="visa"/>
                        </div>

                       
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">Visa Services</h5>
                      
                        <p class="mb-3 font-normal text-gray-500 ">Expert guidance and post-visa support for smooth transitions</p>

                    </div>
                    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow " data-aos="zoom-in">
                        <div className="p-2 bg-[#FBF6E4] w-fit rounded-lg">
                            <img src={clas} className="h-7" alt="class"/>
                        </div>

                       
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">Test Preparation</h5>
                     
                        <p class="mb-3 font-normal text-gray-500 ">Comprehensive classes led by experienced instructors</p>

                    </div>

                </div>

            </section>

            <div className="padd-section text-center wow fadeInUp bg-gray-100 py-12 ">
                <div className="container">
                    <div className="section-title text-center">
                        <div className="text-[24px] font-bold text-gray-600 pb-3" data-aos="fade-up">
                            Test Preparation
                        </div>
                    </div>
                </div>



                <div className="container lg:px-[200px]" data-aos="zoom-in">
                    <OwlCarousel className="owl-theme"   {...options}>
                        <div className="item grayscale hover:grayscale-0 ease-in duration-400 "><img src={ielts} alt="IELTS" /></div>
                        <div className="item grayscale hover:grayscale-0 ease-in duration-400 "><img src={toefl} alt="TOEFL" /></div>
                        <div className="item grayscale hover:grayscale-0 ease-in duration-400 "><img src={sat} alt="SAT" /></div>
                        <div className="item grayscale hover:grayscale-0 ease-in duration-400 "><img src={gre} alt="GRE" /></div>
                        <div className="item grayscale hover:grayscale-0 ease-in duration-400"><img src={gmat} alt="GMAT" /></div>
                    </OwlCarousel>
                </div>
            </div>
            <div className="padd-section text-center wow fadeInUp py-12 ">
            <div className="container">
                    <div className="section-title text-center">
                        <div className="lg:text-[24px] text-[18px] font-bold text-gray-600 pb-3" data-aos="fade-up">
                            Meet Our Team
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-2 gap-2">
                        <div className="imagse" data-aos="zoom-in">
                            <img src={team1} alt="team" />
                            </div>
                            <div className="image lg:p-0 p-5" data-aos="zoom-in">
                            <div className="text-start lg:text-2xl text-md font-bold mt-5 text-yellow-500">Founder & CEO</div>
                            <div className="lg:text-5xl text-3xl text-start font-bold text-[#232536]">SK Koju</div>
                            <div className="text-start lg:pt-5 pt-2 lg:text-[16px] text-[12px]">With over 8 years of experience in the field of recruiting Nepalese students to USA, Mr. Koju ( Graduate from US University - St Cloud State University, MN, USA) is an expert in the field and if not best, one of the best educational counselor in the town. He has experience more than several hundreds of US student visa Success and loves to share his educational experience in the USA.</div>
                            <div className="flex gap-3 mt-5">
                            <a href="https://www.facebook.com/profile.php?id=675647596" target="_blank" rel="noopener noreferrer">
  <img src={facebook} className="lg:h-10 h-7 grayscale hover:grayscale-0" alt="Facebook Profile" />
</a>

                                <img src={insta} alt="instagram" href="https://www.instagram.com/sk_koju/" className="lg:h-10 h-7 grayscale hover:grayscale-0" />
                                <img src={links} alt="instagram" href="https://www.linkedin.com/in/sujit-koju-943295134/" className="lg:h-10 h-7 grayscale hover:grayscale-0" />

                            </div>
                            </div>
                         
                    </div>
                </div>

            </div>


            {/* Reviews */}
            <section className="bg-gray-50">
                <div className="mx-auto max-w-[1340px] px-4 py-12 sm:px-6 lg:me-0 lg:py-16 lg:pe-0 lg:ps-8 xl:py-24">
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3 lg:items-center lg:gap-16">
                        <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right" data-aos="zoom-in-up">
                            <p className="font-[500] mb-4 text-lg uppercase tracking-wider">
                                TESTIMONIALS AND REVIEWS
                            </p>
                            <h2 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                See What our Student Says...
                            </h2>

                            <p className="mt-4 text-gray-700">
                                Don't just take our word for it, here is what some of our
                                customers have to say
                            </p>

                            <div className="hidden lg:mt-8 lg:flex lg:gap-4">
                                <button
                                    aria-label="Previous slide"
                                    id="keen-slider-previous-desktop"
                                    className="rounded-full border border-blue-600 p-3 text-blue-600 transition hover:bg-blue-600 hover:text-white"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="size-5 rtl:rotate-180"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15.75 19.5L8.25 12l7.5-7.5"
                                        />
                                    </svg>
                                </button>

                                <button
                                    aria-label="Next slide"
                                    id="keen-slider-next-desktop"
                                    className="rounded-full border border-blue-600 p-3 text-blue-600 transition hover:bg-blue-600 hover:text-white"
                                >
                                    <svg
                                        className="size-5 rtl:rotate-180"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9 5l7 7-7 7"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="-mx-6 lg:col-span-2 lg:mx-0">
                            <div id="keen-slider" className="keen-slider">
                                <div className="keen-slider__slide">
                                    <blockquote className="flex h-full flex-col justify-between bg-white p-6 shadow-sm sm:p-8 lg:p-12">
                                        <div>
                                            <div className="flex gap-0.5 text-yellow-500">
                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>

                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>

                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>

                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>

                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>
                                            </div>

                                            <div className="mt-4">
                                                <p className="text-2xl font-bold text-blue-600 sm:text-3xl">
                                                    Stayin' Alive
                                                </p>

                                                <p className="mt-4 leading-relaxed text-gray-700">
                                                "Yatu International Education Consultancy helped me find the perfect career path and gave me a big confidence boost. Thank you, Sujit Sir! Highly recommend!"
                                                </p>
                                            </div>
                                        </div>

                                        <footer className="mt-4 text-sm font-medium text-gray-700 sm:mt-6">
                                            &mdash; Jeena Khonju
                                        </footer>
                                    </blockquote>
                                </div>

                                <div className="keen-slider__slide">
                                    <blockquote className="flex h-full flex-col justify-between bg-white p-6 shadow-sm sm:p-8 lg:p-12">
                                        <div>
                                            <div className="flex gap-0.5 text-yellow-500">
                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>

                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>

                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>

                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>

                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>
                                            </div>

                                            <div className="mt-4">
                                                <p className="text-2xl font-bold text-blue-600 sm:text-3xl">
                                                    Stayin' Alive
                                                </p>

                                                <p className="mt-4 leading-relaxed text-gray-700">
                                                "The comprehensive market research and strategic advice from Yatu Education Consultancy were instrumental in securing my dream job. Thank you!" 

                                                </p>
                                            </div>
                                        </div>

                                        <footer className="mt-4 text-sm font-medium text-gray-700 sm:mt-6">
                                            &mdash; Anjesh Duwal
                                        </footer>
                                    </blockquote>
                                </div>

                                <div className="keen-slider__slide">
                                    <blockquote className="flex h-full flex-col justify-between bg-white p-6 shadow-sm sm:p-8 lg:p-12">
                                        <div>
                                            <div className="flex gap-0.5 text-yellow-500">
                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>

                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>

                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>

                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>

                                                <svg
                                                    className="h-5 w-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>
                                            </div>

                                            <div className="mt-4">
                                                <p className="text-2xl font-bold text-blue-600 sm:text-3xl">
                                                    Stayin' Alive
                                                </p>

                                                <p className="mt-4 leading-relaxed text-gray-700">
                                                "The dedication and professionalism at Yatu Consultancy impressed me. Their financial advisory helped me make informed decisions and set clear goals. Very happy with the results."

                                                </p>
                                            </div>
                                        </div>

                                        <footer className="mt-4 text-sm font-medium text-gray-700 sm:mt-6">
                                            &mdash; Sumit Shilakar
                                        </footer>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-8 flex justify-center gap-4 lg:hidden">
                        <button
                            aria-label="Previous slide"
                            id="keen-slider-previous"
                            className="rounded-full border border-blue-600 p-4 text-blue-600 transition hover:bg-blue-600 hover:text-white"
                        >
                            <svg
                                className="size-5 -rotate-180 transform"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9 5l7 7-7 7"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                />
                            </svg>
                        </button>

                        <button
                            aria-label="Next slide"
                            id="keen-slider-next"
                            className="rounded-full border border-blue-600 p-4 text-blue-600 transition hover:bg-blue-600 hover:text-white"
                        >
                            <svg
                                className="h-5 w-5"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9 5l7 7-7 7"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </section>
            {/* Contactus */}

            <section className="bg-gray-100 py-5 pb-10" id="contact">
                <div className="container mx-auto px-4">
                <div className="lg:text-[24px] text-[18px] font-bold text-gray-600 text-center pt-3 pb-3" data-aos="fade-up" >
                   Contact Us
                </div>

                    {/* Contact Information */}
                    <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-4">
                        <div className="" data-aos="fade-right">
                            <div className="mb-4">
                                <h3 className="text-lg font-semibold text-gray-700 mb-2 flex gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6" className="hover:*:text-yellow-500 h-6 ">
                                        <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                                        <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                                    </svg>

                                    Our Address
                                </h3>
                                <p className="text-gray-600">
                                    Bhaktapur
                                    <br />
                                    Kamalbinayak
                                    <br />
                                    Nepal
                                </p>
                            </div>

                            <div className="mb-4">
                                <h3 className="text-lg font-semibold text-gray-700 mb-2">Phone</h3>
                                <p className="text-gray-600 flex gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" className="hover:*:text-yellow-500 h-6 ">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
</svg>



                                    +977 9768406867</p>
                                    
                                <p className="text-gray-600 flex gap-2 pt-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6" className="hover:*:text-yellow-500 h-6 ">
                                        <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clip-rule="evenodd" />
                                    </svg>


                                    01-5920830</p>
                            </div>

                            <div className="mb-4">
                                <h3 className="text-lg font-semibold text-gray-700 mb-2">Email</h3>
                                <p className="text-gray-600 flex gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6" className="hover:*:text-yellow-500 h-6 ">
                                        <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                                        <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                                    </svg>
                                    info@yatuiec.com</p>
                            </div>
                        </div>
                        <div className="col-span-3 " data-aos="fade-right">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.2556668939724!2d85.43769250942036!3d27.678491876099788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1bca4265e05f%3A0xc186d8e0b7466ed7!2sYatu%20International%20Education%20Consultancy!5e0!3m2!1sen!2snp!4v1711536293410!5m2!1sen!2snp" className="w-full h-full border" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="location"></iframe>
                        </div>

                    </div>




                </div>
            </section>
        </>
    );
};

export default HomePage;
