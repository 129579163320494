import React from 'react'
import img1 from "../assets/images/1.png"
import { Link } from 'react-router-dom'
import usa from "../assets/images/usa.png"

const StudyUSA = () => {
  return (
    <>
      <img src={img1} alt="img1" className="lg:w-full h-[200px] md:h-fit lg:h-fit absolute filter grayscale" />

      <Link to="/">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className='lg:w-10 lg:h-10 h-7 w-7 absolute left-0 rounded-full bg-slate-600 lg:p-2 p-1 hover:bg-gray-800 m-2'>
                    <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                    <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                </svg>
            </Link>

    <div className='lg:pt-[400px] md:pt-[200px] pt-[200px]'>

    </div>
 <div className='grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2'>
    <div>
    <div className='text-gray-600 lg:text-[38px] text-[24px] font-bold lg:px-4 px-2' data-aos="fade-right"> Study in USA</div>
    <p className='lg:p-5 p-3 text-justify lg:text-[16px] text-[12px] ' data-aos="fade-right">

Studying in the USA offers an unparalleled opportunity for international students to immerse themselves in a world-renowned education system known for its academic excellence and diverse range of programs. With thousands of institutions to choose from, including prestigious universities and colleges, students can pursue their academic interests and career aspirations in a dynamic and innovative environment. Beyond academics, studying in the USA provides access to vibrant cultural experiences, fostering personal growth and global perspective. From bustling urban campuses to serene college towns, the USA offers a diverse array of settings for students to thrive academically, socially, and professionally. With a commitment to fostering innovation, critical thinking, and creativity, studying in the USA opens doors to endless possibilities for success and personal fulfillment.
</p>
<Link to="/" href="#contact">


<button className='bg-rose-600 text-white p-3 mx-5 hover:bg-rose-800' data-aos="fade-right"> Contact Us for More detail</button>
</Link>    </div>
    <div>
        <img src={usa} alt="img1" className="lg:w-full h-[300px] md:h-fit lg:h-fit floating lg:pt-0 pt-3"  />
    </div>
  
  
  
</div>
<div className='text-[24px] font-bold text-gray-600 text-center pt-3 pb-3' data-aos="fade-up">
    Why Study in the USA?
</div>
<div className='lg:p-5 p-3 text-justify lg:text-[16px] text-[12px]'>
Globally renowned education system with diverse academic programs and top-notch faculty.
Opportunities for personal and professional growth through access to cutting-edge research and internships.
Vibrant and diverse cultural environment, fostering a global perspective.
Abundant opportunities for internships and job placements in renowned companies and industries.
Transformative experience fostering independence, creativity, and critical thinking skills.
</div>



    </>
  )
}

export default StudyUSA