import React from 'react'
import img4 from "../assets/images/4.png"
import { Link } from 'react-router-dom'
import aus from "../assets/images/aus.png"
import Navbar from '../Component/Navbar'

const StudyAustralia = () => {
    return (
        <div id="australia">
           
            <img src={img4} alt="img1" className="lg:w-full h-[200px] w-cover md:h-fit lg:h-fit absolute filter grayscale"  id="aus"/>

            <Link to="/">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className='lg:w-10 lg:h-10 h-7 w-7 absolute left-0 rounded-full bg-slate-600 lg:p-2 p-1 hover:bg-gray-800 m-2'>
                    <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                    <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                </svg>
            </Link>

            <div className='lg:pt-[400px] md:pt-[200px] pt-[200px]'>

            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2'>
                <div>
                    <div className='text-gray-600 lg:text-[38px] text-[24px] font-bold lg:px-4 px-2' data-aos="fade-right"> Study in Australia</div>
                    <p className='lg:p-5 p-3 text-justify lg:text-[16px] text-[12px]' data-aos="fade-right">



                        Studying in Australia offers an unparalleled educational experience in a vibrant and diverse setting. Renowned for its world-class universities and high-quality academic programs, Australia attracts students from all corners of the globe. With its welcoming and inclusive culture, students can immerse themselves in a multicultural environment that fosters personal growth and global perspective. From bustling cities to breathtaking natural landscapes, Australia offers a unique blend of academic excellence and outdoor adventure. Moreover, international students have access to post-study work opportunities, allowing them to gain valuable work experience while exploring everything that Australia has to offer. A degree from an Australian institution is highly respected worldwide, opening doors to exciting career prospects and future opportunities.    </p>
                    <Link to="/" href="#contact">


                        <button className='bg-rose-600 text-white p-3 mx-5 hover:bg-rose-800' data-aos="fade-right"> Contact Us for More detail</button>
                    </Link>
                </div>
                <div>
                    <img src={aus} alt="img1" className="lg:w-full h-[300px] md:h-fit lg:h-fit floating lg:pt-0 pt-3" />
                </div>



            </div>
            <div className='text-[24px] font-bold text-gray-600 text-center pt-3 pb-3' data-aos="fade-up">
                Why Study in the Australia?
            </div>
            <div className='lg:p-5 p-3 text-justify lg:text-[16px] text-[12px]'>
                Academic Excellence: Australia is home to world-class universities and colleges known for their high-quality education, cutting-edge research, and innovative teaching methods.

                Diverse Range of Programs: With a wide variety of academic programs and disciplines available, students can find the perfect fit for their interests and career aspirations.

                Vibrant Cultural Experience: Australia's multicultural society offers an enriching cultural experience, providing opportunities to interact with people from diverse backgrounds and perspectives.

                Safe and Welcoming Environment: Australia is recognized for its safe and welcoming environment, making it an ideal destination for international students to live, study, and thrive.

                Work Opportunities: Australia offers ample opportunities for part-time work during studies and post-study work visas, allowing students to gain valuable work experience and explore career opportunities after graduation.

                Stunning Natural Landscapes: From beautiful beaches to lush rainforests and the iconic Outback, Australia's breathtaking natural landscapes provide endless opportunities for exploration and adventure.

                Global Recognition: A degree from an Australian institution is highly respected and recognized worldwide, opening doors to a wide range of career opportunities and further academic pursuits globally.
            </div>



        </div>
    )
}

export default StudyAustralia